import { assignRootRoute } from 'layout_congress/js/urls.mts'
import type { RouteRecordRaw } from 'vue-router'

const pageRoutes: RouteRecordRaw[] = [
    {
        name: 'login',
        path: '/:lang/accounts/login',
        component: () => import('./LoginPage.vue'),
    },
    {
        name: 'admin-login',
        path: '/:lang/admin/login',
        component: () => import('./LoginPage.vue'),
    },
]

const popupRoutes: RouteRecordRaw[] = [
  {
    name: 'bankid',
    path: 'bankid',
    component: () => import('bank_id/vue/BankIDHandlerComponent.vue'),
    meta: { 'defaultModalClass': null, },
  }
]

export const routes = pageRoutes.map(route => {
  return {
    ...route,
    children: [
      ...(route.children ?? []),
      ...assignRootRoute(route,popupRoutes),
    ],
  } as RouteRecordRaw
})

export default routes
